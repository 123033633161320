.shared {
  &-wrapper {
      position: relative;
  }

  &-spin {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  &-content {
    background-color: #FFFFFF;
    box-shadow: 3px 4px 10px rgba(32, 73, 132, 0.4);
    border-radius: 4px;
    box-sizing: border-box;

    padding: 5px;
    position: absolute;
    display: block;
    z-index: 1001;
    right: 14px;
    top: 14px;

    min-width: 160px;
    max-height: 280px;

    .list-wrapper {
      display: flex;
      flex-direction: column;

      max-height: 180px;
      min-height: 40px;

      overflow-x: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
        background-color: #FFFFFF;
        padding: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #9594B9;
      }

      &::-webkit-scrollbar-track {
        border-radius: 3px;
        background-color: #EBEFF6;
      }

      .list-item {
        display: flex;
        flex-direction: row;
        padding-right: 6px;
  
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.1px;
        line-height: 24px;
  
        cursor: pointer;
  
        div {
          color: #3463A7;
  
          padding: 8px;
  
          &.__icon {
            padding: 8px 3px 8px 6px;
          }
  
          &.small {
            font-size: 12px;
            line-height: 12px;
          }
  
          &.disabled {
            cursor: not-allowed;
            opacity: 0.7;
          }
        }
      }
    }
  }
}