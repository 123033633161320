.header {
  background: #FFFFFF;
  color: #3463A7;
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;

  border-bottom: 1px solid #D5E0E6;

  &-logo {
    padding: 10px 0;
    display: flex;
    align-items: center;
    max-width: 150px;

    svg {
      max-height: 30px;
    }
  }

  .small-col {
    flex: 0;
  }
}
