@mixin text-general {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

@mixin text-cell {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}

@mixin text-cell-stats {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
}

@mixin text-general-accent {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

@mixin text-tabbar {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

@mixin text-tabbar-accent {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

@mixin no-wrap {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Текст содержимого фильтра (не placeholder)
@mixin text-filter-content {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

@mixin text-filter-label {
  @include app-font-family;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: $color-filter-label;
}

@mixin app-font-family {
  font-family: 'Roboto', sans-serif;
}

@mixin text-table-event-header {
  @include text-general;
  font-weight: 500;
}

@mixin text-content-layout-header {
  @include text-general-accent;
  @include app-font-family;
  font-size: 20px;
}

@mixin text-schedule-dummy {
  @include text-general;
  @include app-font-family;
  font-size: 16px;
  text-align: center;
}
