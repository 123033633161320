@import "@styles/mixins";

.showselect {
  min-height: 28px;

  &-wrapper {
    min-height: 28px;
    cursor: pointer;
    padding: 0;
    display: flex;

    &.one-line {
      .multiselect {
        &-selector {
          flex-wrap: nowrap;
          overflow: hidden;

          &-label {
            white-space: nowrap;
          }
        }
      }
    }

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 24px;
  }

  &-placeholder {
    box-sizing: border-box;
    background-color: transparent;
    border-radius: 4px;
    padding: 2px 0;
    color: #98A1AF;

    &_oneline {
      @include no-wrap;
    }
  }

  &-dropdown {
    min-width: 400px;
    min-height: 200px;

    background-color: #FFFFFF;
    box-shadow: 3px 4px 10px rgba(32, 73, 132, 0.4);
    border-radius: 4px;
    box-sizing: border-box;

    padding: 5px;
    position: absolute;
    display: block;
    z-index: 1001;
  }

  &-options {
    display: flex;
    flex-direction: column;

    max-height: 220px;
    overflow-x: hidden;
    overflow-y: auto;

    padding: 0 10px;

    > div {
      white-space: nowrap;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #FFFFFF;
      padding: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #9594B9;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #EBEFF6;
    }

    &-group {
      padding-left: 29px;
    }

    &-option {
      display: flex;
      flex-direction: row;

      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      letter-spacing: 0.1px;
      line-height: 24px;

      &.lined {
        border-top: 1px solid #EDEDED;
      }

      &:last-child {
        border-bottom: 1px solid #EDEDED;
      }

      cursor: pointer;

      &:hover {
        background-color: #EDEDED;
      }

      div {
        color: #585771;
        padding: 0;

        &.__icon {
          padding: 8px 3px 8px 6px;
        }

        &.small {
          font-size: 12px;
          line-height: 12px;
        }

        &.disabled {
          cursor: not-allowed;
          opacity: 0.7;
        }

        span {
          width: 40px;
          display: inline-block;

          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          letter-spacing: 0.1px;
          line-height: 24px;

          &:first-child {
            color: #9294A0;
            width: 20px;
            text-align: right;
            padding-right: 5px;
          }

          &:last-child {
            color: #9294A0;
          }
        }
      }
    }
  }

  &-dates-wrapper {
    display: flex;
    padding: 10px;

    .year-wrapper {
      width: 100px;
    }

    .month-wrapper {
      width: auto;
      flex: 1;
      margin: 0 10px;
    }

    .week-wrapper {
      width: 100px;
    }
  }

  &-selector {
    display: flex;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #EBEFF6;
    flex-wrap: wrap;

    &-drop {
      width: 1px;
      height: 18px;
      background: #65D98C;
      box-shadow: 0px 0px 0px 2px rgba(101, 217, 140, 0.4);
      border-radius: 3px;
      margin: 5px 2px;
    }

    &-label {
      box-sizing: border-box;
      background-color: #EBEFF6;
      border-radius: 4px;
      padding: 2px 8px;

      display: flex;

      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.1px;
      line-height: 24px;

      &-name-dropdown {
        cursor: move;
      }

      &-trash {
        box-sizing: border-box;
        padding: 3px 4px 5px 4px;
        height: 24px;

        svg {
          cursor: pointer;
        }
      }
    }

    input.label-edit {
      border: none;
      background-color: transparent;
      width: auto;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.1px;    }
  }
}
