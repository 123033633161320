@import '@styles/globals';

.schedule-table-column {
  min-width: $min-schedule-table-column-width;
  flex-shrink: 0;
  flex-grow: 1;
  border-right: 1px solid $color-schedule-table-border;

  &:last-child {
    border-right: none;
  }

  &__cells {
    position: relative;
    border-top: 1px solid $color-schedule-table-border;
    background: repeating-linear-gradient(
      135deg,
      $color-stripes-light,
      $color-stripes-light $spacing-3,
      $color-stripes-dark $spacing-3,
      $color-stripes-dark $spacing-6
    );
  }

  &__cell-item {
    position: absolute;
    width: 100%;
    border-bottom: 1px solid $color-schedule-table-border;
  }
}
