@import '@styles/globals';

.schedule-table-header-cell {
  min-width: $min-schedule-table-column-width;
  flex-shrink: 0;
  flex-grow: 1;
  border-right: 1px solid $color-schedule-table-border;

  &:last-child {
    border-right: none;
  }

  &-inner {
    padding: $spacing-1 $spacing-2;
    min-height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__text {
      @include text-table-event-header;
      margin: 0 0 0 $spacing-2;
    }

    &__stats {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
