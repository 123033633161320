@use 'sass:color';

// background colors
$color-bg-page: #f8fafb;

// typography colors
$color-text-primary: #051a3c;
$color-text-active: #3463a7;

// tab bar colors
$color-bg-tab-active: #ffffff;
$color-bg-tab-layout-oneline: #ffffff;
$color-tab-oneline-active-border: #3463a7;

// schedule table colors
$color-bg-schedule-table: #ffffff;
$color-schedule-table-border: #d9e0ed;
$color-bg-schedule-table-legend: #ffffff;
$color-bg-active-cell: #eff1f6;
$color-timestub: #fefefe;

// icon colors
$color-icon-cross: #716f6f;
$color-icon-cross-bg: #d9e0ed;

// scrollbar colors
$color-sb-border: #d9e0ed;
$color-sb-track: $color-sb-border;
$color-sb-thumb: #716f6f;

// table filters colors
$color-filter-border: #e0e0e0;
$color-filter-main: #212529;
$color-filter-label: #98a1af;

// schedule radio buttons colors
$color-schedule-radio-button-audience-y: #3463a7;
$color-schedule-radio-button-audience-x: #f15b5b;

// schedule event group colors
$color-schedule-event-group-audience-y: $color-schedule-radio-button-audience-y;
$color-schedule-event-group-audience-x: $color-schedule-radio-button-audience-x;

// schedule table cells stripes background
$color-stripes-light: #ffffff;
$color-stripes-dark: #fafbfd;
