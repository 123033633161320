@import '@styles/globals';

.layout-container {
  position: relative;
  border-left: 1px solid $color-schedule-table-border;
  border-right: 1px solid $color-schedule-table-border;

  &_full-height {
    flex-grow: 1;
  }

  &_no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacing-5 0;
  }
}

.schedule-table-layout {
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  &_header {
    border: none;
    background-color: $color-bg-page;
  }

  &__header-columns-wrapper {
    overflow: hidden;
    flex-grow: 1;
    flex-shrink: 1;
  }

  &__columns {
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    &_fixed {
      flex-shrink: 0;
      border-right: 1px solid $color-schedule-table-border;
    }

    &_content {
      will-change: transform;
      flex-grow: 1;
      flex-shrink: 1;
      display: grid;
      grid-auto-columns: minmax(320px, 100%);
      grid-auto-flow: column;
    }

    &_content-scrolled {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  opacity: 1;
  background: scale-color($color-bg-page, $blackness: 1%);

  &__icon {
    font-size: $circullar-loader-size;
  }
}
