button.composition-button {
  height: 28px;
  padding: 2px 8px;

  border-radius: 4px;

  background-color: #FFFFFF;

  display: flex;

  svg {
    width: 20px;
    height: 20px;
    display: inline;
    align-self: center;
  }

  span {
    color: #3463A7;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    letter-spacing: 0.1px;

    padding-left: 8px;
    line-height: 24px;
  }

  &.add-block {
    background-color: #F15B5B;
    width: 173px;

    span {
      color: #FFFFFF;
    }
  }

  &.add-condition {
    background-color: #F1BE5B;
    width: 187px;

    span {
      color: #585771;
    }
  }

  &.delete-block {
    background-color: #FFFFFF;
    width: 163px;

    span {
      color: #F15B5B;
    }
  }
}

button.form-button {
  height: 40px;

  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  display: flex;

  padding: 8px 16px;

  span {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    align-items: center;
    letter-spacing: 0.1px;

    color: #585771;
  }

  &.back {
    svg {
      width: 17px;
      height: 18px;
      display: inline;
      align-self: center;
      margin-right: 10px;
    }
  }

  &.save {
    background-color: #3463A7;

    span {
      color: #FFFFFF;
    }
  }
}

div.icon-button {
  display: inline-block;
  padding: 4px;
  width: 28px;
  height: 28px;

  cursor: pointer;

  &[disabled]> svg {
    opacity: 0.3;
  };

  > svg {
    width: 20px;
    height: 20px;
  }
}