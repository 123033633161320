@import '@styles/mixins';

.tab-bar {
  width: 100%;
  display: flex;
  overflow-x: auto;
  border-bottom: 1px solid $color-schedule-table-border;
  min-height: 49px;

  @include with-horizontal-scrollbar;

  &_view {
    &-transparent {
      background-color: transparent;
    }

    &-filled {
      background-color: $color-bg-tab-layout-oneline;
    }
  }
}
