.population-wrapper {
  display: inline-block;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;

  position: relative;

  box-sizing: border-box;

  .title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #98A1AF;
    min-width: 92px;
  }

  .icon {
    width: 24px;
    height: 24px;
    padding: 3px 4px 5px 4px;
    margin-right: 5px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .arrow {
    padding: 10px 8px;

    &.top {
      transform: rotate(180deg);
    }
  }

  .population-title {
    padding: 5px 4px;
    display: flex;
    cursor: pointer;
    background: #F5F7FB;
    border-radius: 30px;
  }

  .population-menu {
    position: absolute;
    background-color: #FFFFFF;
    padding: 16px;
    border-radius: 0 0 8px 8px;
    width: 100%;
    margin: 11px 0 0 -10px;

    border-bottom: 1px solid #D5E0E6;
    border-right: 1px solid #D5E0E6;
    border-left: 1px solid #D5E0E6;

    &-item {
      padding: 6px 4px;
      display: flex;
      cursor: pointer;
    }
  }

}