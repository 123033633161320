.composition {
  background-color: rgba(52, 99, 167, 0.1);
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 8px;

  &-select {
    width: 100%;
    display: flex;

    div {
      align-self: center;
    }
  }

  &-block {
    > .item:first-child {
      margin-top: 16px;
    }
  }

  &-buttons {
    flex: 1;

    display: flex;
    justify-content: flex-end;

    .wrapper {
      margin-left: 8px;
    }
  }

  .two-columns {
    display: flex;

    & > div {
      flex: 1;

      &:first-child {
        margin-right: 8px;
      }

      &:last-child {
        margin-left: 8px;
      }
    }
  }

  .condition {
    margin-bottom: 8px;
    background-color: #FFFFFF;
    border-radius: 4px;
    padding: 8px;

    width: 100%;
    display: flex;

    &-wrapper {
      display: flex;
      flex: 1;
      justify-content: left;

      min-height: 28px;

      .field {
        margin-right: 8px;
        min-height: 28px;

        &.full-width:last-child {
          flex: 1;
        }
        
        span {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          letter-spacing: 0.1px;
          line-height: 24px;
        }
      }
    }

    &-actions {
      height: 28px;
    }

    input {
      &:focus {
        outline: none;
      }

      &.number {
        width: 90px;
        text-align: right;
      }

      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.1px;
      line-height: 24px;

      padding: 2px 8px 0 8px;

      border: 1px solid #E4EBF3;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }

  .timeband {
    margin-bottom: 8px;
    background-color: #FFFFFF;
    border-radius: 4px;
    padding: 8px;

    width: 100%;
    display: flex;

    &-wrapper {
      display: flex;
      flex: 1;
      justify-content: left;

      height: 28px;

      .field {
        margin-right: 8px;
        height: 28px;

        &.full-width:last-child {
          flex: 1;
        }
      }
    }

    &-actions {
      height: 28px;
    }

    input {
      &:focus{
        outline: none;
      }

      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.1px;
      line-height: 24px;
    }

    .time-picker {
      width: 55px;
      padding: 2px 8px 0 8px;

      border: 1px solid #E4EBF3;
      box-sizing: border-box;
      border-radius: 4px;
    }

    .react-date-picker__wrapper {
      border: 1px solid #E4EBF3;
      border-radius: 4px;
      padding: 0 6px;
    }

    .react-date-picker {
      span {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.1px;
        line-height: 24px;
      }
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: none;
  }

  .ant-cascader {
    border: none;
    box-sizing: border-box;
    height: 28px;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.1px;

    color: #585771;

    .ant-select-selector {
      border: none;
      height: 28px;
      border-radius: 4px;
      padding: 0;

      .ant-select-selection-item {
        height: 28px;
        padding: 2px 6px;
        margin: 0;
        border: 'none';
        border-radius: 0;
        background-color: #EBEFF6;

        &-content {
          line-height: 24px;
          height: 24px;
        }

        &-remove {
          height: 24px;
          line-height: 24px;

          > .anticon {
            vertical-align: baseline;
          }
        }
      }
    }
  }
}

.ant-cascader-dropdown {
  border-radius:  4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  .ant-cascader-menu-item {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.1px;

    color: #585771;
  }
}

.multiselect-options-option div.advert-tree-type {
  padding: 0;
  align-self: center;
  padding-bottom: 3px;
}