.sources-form {
  background: #FFFFFF;
  border-radius: 8px;

  &-filters {
    padding: 16px;
    display: flex;
    flex-direction: column;
    background-color: #EBEFF6;
  }

  &-filter {
    flex: 1;

    background-color: #FFFFFF;
    border-radius: 4px;
    padding: 8px 16px;

    .chakra-form-control {
      padding: 0 !important;
      margin: 0 !important;
    }

    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .premier-wrapper {
    span {
      font-weight: 500;
      font-size: 14px;
      color: #98A1AF;
    }
  }

  &-buttons {
    display: flex;
    padding: 20px 16px;
    flex-direction: row-reverse;
    gap: 16px;
  }

  &-row {
    display: flex;
    padding: 0 16px;
    border-bottom: 1px solid #E4EBF3;

    &-title {
      padding: 16px;
    }

    &-field {
      margin: 0 16px;

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .axes {
    display: flex;
    flex-direction: row;
    margin: 0;
    &-axis {
      flex: 1 50%;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }

      .selector-drop {
        width: 1px;
        height: 18px;
        background: #65D98C;
        box-shadow: 0px 0px 0px 2px rgba(101, 217, 140, 0.4);
        border-radius: 3px;
        margin: 5px 2px;
      }
    
      &-label {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.7px;
        margin-bottom: 8px;

        color: #98A1AF;
      }

      &-options {
        min-height: 116px;
        background: #FFFFFF;
        border-radius: 4px;

        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        padding: 8px;

        align-items:flex-start;
        align-content:flex-start;
      }
    }

    &-card {
      background: #3463A7;
      border-radius: 4px;
      height: 28px;
      padding: 1px 8px 3px 8px;

      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;

      color: #FFFFFF;

      cursor: move;
    }
  }
}