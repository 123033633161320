@import '@styles/globals';
@mixin lightenedBackground($color: $color-bg-schedule-table, $lightness: 35%) {
  background: scale-color($color, $lightness: $lightness);
}

@mixin circle-color($circle-color) {
  @include border($circle-color);

  &:checked {
    @include lightenedBackground($circle-color);
  }
}

.radio-button {
  @include square($spacing-4);
  @include border($color-schedule-table-border);
  appearance: none;
  background: $color-bg-schedule-table;
  outline: 1px solid $color-schedule-table-border;
  border-radius: 50%;
  cursor: pointer;

  &:hover,
  &:checked {
    border-width: 3px;
  }

  &_color {
    &_none {
      @include border($color-schedule-table-border);
      background: $color-bg-schedule-table;

      &:checked {
        border-width: 1px;
      }
    }

    &_audienceX {
      @include circle-color($color-schedule-radio-button-audience-x);
    }

    &_audienceY {
      @include circle-color($color-schedule-radio-button-audience-y);
    }
  }
}
