$global-spacing-map: (
  unit-0_5: 2px,
  unit-1: 4px,
  unit-2: 8px,
  unit-3: 12px,
  unit-4: 16px,
  unit-5: 20px,
  unit-6: 24px,
  unit-7: 28px,
  unit-8: 32px,
  unit-9: 36px,
  unit-10: 40px,
  unit-11: 44px,
  unit-12: 48px,
  unit-13: 52px,
  unit-14: 56px,
  unit-15: 60px,
);
$spacing-0_5: map-get($global-spacing-map, 'unit-0_5');
$spacing-1: map-get($global-spacing-map, 'unit-1');
$spacing-2: map-get($global-spacing-map, 'unit-2');
$spacing-3: map-get($global-spacing-map, 'unit-3');
$spacing-4: map-get($global-spacing-map, 'unit-4');
$spacing-5: map-get($global-spacing-map, 'unit-5');
$spacing-6: map-get($global-spacing-map, 'unit-6');
$spacing-7: map-get($global-spacing-map, 'unit-7');
$spacing-8: map-get($global-spacing-map, 'unit-8');
$spacing-9: map-get($global-spacing-map, 'unit-9');
$spacing-10: map-get($global-spacing-map, 'unit-10');
$spacing-11: map-get($global-spacing-map, 'unit-11');
$spacing-12: map-get($global-spacing-map, 'unit-12');
$spacing-13: map-get($global-spacing-map, 'unit-13');
$spacing-14: map-get($global-spacing-map, 'unit-14');
$spacing-15: map-get($global-spacing-map, 'unit-15');

// border radiuses
$border-radius-1: 5px;
$border-radius-2: 8px;
$border-radius-4: 16px;
$border-radius-6: 24px;

// global spacings
$global-custom-scrollbar-width: 7px;
$global-border-radius: $border-radius-1;

// table column widths
$min-schedule-table-column-width: 110px;

// loader size
$circullar-loader-size: 36px;
