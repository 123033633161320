.card-row {
  display: flex;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  .card-col {
    flex: 1;
    margin-left: 16px;

    &:last-child {
      margin-right: 16px;
    }

    label {
      background-color: #FFFFFF;
      width: auto;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.15px;
      color: #98A1AF;
      height: 12px;
      margin: 0;
      margin-bottom: 9px;
    }

    .premier-wrapper span {
      color: #9594B9;
    }

    span.ant-radio + * {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}

.card-content {
  .statistics {
    display: block;
    width: 400px;
    padding: 0 30px 30px 30px;
  }
}

.card-title {
  .actions {
    flex: 10;
    display: flex;

    svg {
      cursor: pointer;
    }
  }
}

.results-button {
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
}
