@import '@styles/globals';

.radio-button-group {
  display: flex;
  background: transparent;

  &__button {
    margin-left: $spacing-2;
  }
}
