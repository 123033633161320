$z-index-sidebar: 5;
$z-index-status-comment: 10;

.sidebar {
  width: 260px;
  border-right: 1px solid #FAF4F4;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  padding-top: 55px;
  background-color: #FFFFFF;
  z-index: $z-index-sidebar;

  &.mini {
    width: 52px;

    .sidebar-nav li a {
      svg {
        margin-right: 0;
      }

      span {
        display: none;
      }
    }

    .roller {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &-nav {
    margin: 0 8px;
    padding: 0;
    list-style: none;

    li {
      height: 36px;

      &.disabled {
        a {
          span {
            color: #E4E4E4;
          }

          svg {
            fill: #E4E4E4 !important;
          }
        }
      }
    }

    a {
      height: 36px;
      display: flex;
      align-items: center;
      padding: 6px 8px;
      border-radius: 4px;

      svg {
        margin-right: 15px;
        fill: #98A1AF;
        width: 20px;
        height: 20px;
      }

      span {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;

        letter-spacing: 0.1px;

        color: #98A1AF;
      }

      &:hover,
      &.active {
        text-decoration: none;

        span {
          color: #3B7BD7;
        }

        svg {
          fill: #3463A7 !important;
        }
      }

      &:hover {
        background-color: #F8FAFF;
      }
    }
  }

  .roller {
    display: flex;
    margin: 10px;
    justify-content: flex-end;

    div {
      padding: 8px;
      cursor: pointer;
    }
  }
}
.sidebar-status{
  width: 20px;
  height: 20px;

  margin: 18px;

  position: absolute;
  bottom: 0px;
  right: 0px;
}
.mini .sidebar-status{
  margin: 18px 16px;
}

.status-rectangle{
  width: 10px;
  height: 10px;
  margin-top: -5px;

  transform: rotate(45deg);

  background-color: #5C5C5C;

}

.status-text{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  width: fit-content;
  padding: 3px 8px;

  background-color: #5C5C5C;

  border-radius: 4px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFF;

& span{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFF;
}

// position: absolute;
// bottom: 34px;
// // left: calc(81% - 28px);
// right: calc(-50% + 232px);
}

.status-comment{
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  right: calc(28px - 50%);

  bottom: 34px;

  z-index: $z-index-status-comment;
  // display: none;
}

.mini .status-comment{
  display: none;
}

// .sidebar-status:hover .status-comment{
//   // display: none;
//   opacity: 0;
// }

.status-container{
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.hidden{
  display: none;
}
