.collections-form {
  margin: 20px;

  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  .form-header {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1px;
    padding: 16px 24px;
    border-bottom: 1px solid #E4EBF3;
  }

  .form-content {
    padding: 0 24px 24px 24px;
  }

  .composition-field {
    margin: 20px 0;

    > label {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.1px;
      margin-bottom: 20px;
    }
  }

  &-buttons {
    margin: 20px;

    > div:first-child {
      flex: 1;
    }
  }
}

.checkbox-item {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  height: 40px;
  padding: 8px 16px;
  margin-top: 24px;

  label {
    margin: 0;
    display: inline;
    margin-left: 16px;
  }
}

.input-border {
  border: 1px solid  #E0E0E0;
  border-radius: 4px;
  height: 40px;
  padding: 8px 16px;

  margin-top: 24px;

  label {
    position: absolute;
    top: -12px;
    left: 12px;
    padding: 0 4px;
    background-color: #FFFFFF;
    width: auto;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;   
    letter-spacing: 0.15px;
    
    color: #98A1AF;
  }

  input {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.15px;

    color: #051A3C;

    width: 100%;
    border: none;

    &:focus{
      outline: none;
    }
  }

  .text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.15px;

    color: #051A3C;
  }

  .ant-select {
    width: 100%;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 24px;
    border: none;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.15px;

    color: #051A3C;

    padding: 0 11px 0 0;

    &:focus{
      border: none;
      outline: none;
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: none;
  }
}

.ant-select-tree {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.15px;

  color: #051A3C;

  .ant-select-tree-node-content-wrapper {
    border-radius: 4px;
    padding: 3px 4px;
  }

  .ant-select-tree-switcher {
    padding: 3px 0;
  }

  .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background-color: #E8F1FF;
  }

  .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
    color: #000000;
  }
}

.two-columns {
  display: flex;

  > div:first-child {
    margin-right: 12px;
  }

  > div:last-child {
    margin-left: 12px;
  }
}