@import '@styles/globals';

.content-layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;

  &__child {
    margin-top: $spacing-3;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;

    &_no-grow {
      flex-grow: 0;
    }
  }

  &__title {
    @include text-content-layout-header;
  }
}
