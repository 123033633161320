@import '@styles/globals';

.cell-content-tooltip {
  display: flex;
  flex-direction: column;

  &__item {
    margin-bottom: $spacing-1;
  }

  &__link {
    text-decoration: underline;

    &:hover {
      color: inherit;
    }
  }

  &__radio-buttons {
    align-self: flex-end;
  }
}
