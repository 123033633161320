@use "sass:math";
@import "./utils/colors";
@import "./utils/typography";
@import "./utils/spacing";
@import "./utils/font";

$transition-default: 0.2s ease;

@mixin transition($properties...) {
  $animated-property: #{""};

  @if length($properties) > 1 {
    @for $i from 1 through length($properties) - 1 {
      $animated-property: $animated-property
        nth($properties, $i)
        $transition-default#{", "};
    }
  }

  transition: $animated-property nth($properties, length($properties))
    $transition-default;
}

@mixin units($property-name, $coef: 1) {
  @each $unit, $value in $global-spacing-map {
    &-#{$unit} {
      #{$property-name}: #{$coef * $value};
    }
  }
}

@mixin square($size) {
  height: $size;
  width: $size;
}

@mixin with-active-effect() {
  &:hover,
  &:active,
  &:focus {
    @content;
  }
}

@mixin with-horizontal-scrollbar(
  $line-color: $color-sb-border,
  $track-color: $color-sb-track,
  $thumb-color: $color-sb-thumb
) {
  // Firefox
  scrollbar-color: $thumb-color $track-color;
  scrollbar-width: thin;

  // webkit
  &::-webkit-scrollbar {
    height: $global-custom-scrollbar-width;
  }

  &::-webkit-scrollbar-thumb {
    height: $global-custom-scrollbar-width;
    background-color: $thumb-color;
    border-radius: $global-border-radius;
    border: 1px solid $line-color;
    box-sizing: border-box;
  }

  &::-webkit-scrollbar-track {
    height: $global-custom-scrollbar-width;
    background-color: $track-color;

    @include transition(background-color);

    &:hover {
      background-color: $line-color;
    }
  }
}

@mixin square($size) {
  height: $size;
  width: $size;
}

@mixin border($color, $size: 1px) {
  border: $size solid $color;
}
