$z-index-radio-buttons: 5;

.cell-content-inline {
  position: relative;

  &__radio-buttons {
    position: absolute;
    right: 0;
    top: 0;
    z-index: $z-index-radio-buttons;
  }
}
