@import '@styles/mixins';

.schedule-table {
  &__filters {
    display: flex;
    flex-wrap: wrap;
    margin: -$spacing-2;
  }

  &__filter {
    min-width: 240px;
    max-width: 100%;
    padding: $spacing-2;
    flex: 1 0;

    &_full-width {
      flex-basis: 100%;
    }

    // todo KTS переделать стили фильтров без использования label
    &-content {
      @include text-filter-content;

      position: relative;
      display: inline-block;
      min-height: 44px;
      padding: $spacing-2 $spacing-4;
      margin-top: $spacing-3;
      color: $color-filter-main;
      border-radius: $spacing-1;
      border: 1px solid $color-filter-border;
      background-color: transparent;

      label {
        @include text-filter-label;

        position: absolute;
        top: -10px;
        left: 12px;
        padding: 0 $spacing-1;
        margin: 0;
        height: 12px;
        background-color: $color-bg-page;
      }
    }
  }
}
