.form-template-wrapper {
  display: flex;
  align-items: center;

  .form-control {
    max-width: 300px;
    min-width: 300px;
    width: 100px;
  }

  .icon-button {
    padding: 7px 7px;
    margin-top: 8px;
  }
}