button {

  &.ui-btn {
    height: 32px;
    border-radius: 8px;
    padding: 4px 8px;

    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

    display: flex;

    div {
      padding: 0 10px 0 2px;
      height: 24px;
      display: flex;

      svg {
        align-self: center;
      }
    }

    span {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    &.search {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 44px;
      width: 44px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &.primary {
    background-color: #3463A7;

    &:hover {
      background-color: #3D77CA;
    }

    &:focus {
      background-color: #3463A7;
    }

    &:disabled {
      opacity: 0.5;
    }

    span {
      color: #FFFFFF;
    }
  }

  &.base {
    background-color: #FFFFFF;

    display: flex;

    &:hover {
      span {
        color: #3D77CA;
      }
    }

    &:focus {
      span {
        color: #3463A7;
      }
    }

    &:disabled {
      opacity: 0.5;
    }

    span {
      color: #3463A7;
    }
  }

  &.danger {
    background-color: #F15B5B;

    &:hover {
      background-color: #FF8484;
    }

    &:focus {
      background-color: #F15B5B;
    }

    &:disabled {
      opacity: 0.5;
    }

    span {
      color: #FFFFFF;
    }
  }

}