@import "@styles/mixins";

.tab-filled {
  &_chosen {
    color: $color-text-active;
    position: relative;

    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      content: "";
      background-color: $color-tab-oneline-active-border;
    }
  }
}
