@import "@styles/mixins";

.multiselect {
  min-height: 28px;

  &-wrapper {
    min-height: 28px;
    cursor: pointer;
    padding: 0;
    display: flex;

    &.one-line {
      .multiselect {
        &-selector {
          flex-wrap: nowrap;
          overflow: hidden;

          &-label {
            white-space: nowrap;
          }
        }
      }
    }

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 24px;
  }

  &-placeholder {
    box-sizing: border-box;
    background-color: transparent;
    border-radius: 4px;
    padding: 2px 0;
    color: #98A1AF;

    &_oneline {
      @include no-wrap;
    }
  }

  &-dropdown {
    background-color: #FFFFFF;
    box-shadow: 3px 4px 10px rgba(32, 73, 132, 0.4);
    border-radius: 4px;
    box-sizing: border-box;

    padding: 5px;
    position: absolute;
    display: block;
    z-index: 1001;
  }

  &-options {
    display: flex;
    flex-direction: column;

    max-height: 320px;
    overflow-x: hidden;
    overflow-y: auto;

    > div {
      white-space: nowrap;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: #FFFFFF;
      padding: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #9594B9;
    }

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: #EBEFF6;
    }

    &-group {
      padding-left: 29px;
    }

    &-option {
      display: flex;
      flex-direction: row;
      padding-right: 6px;

      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.1px;
      line-height: 24px;

      cursor: pointer;

      div {
        color: #3463A7;

        padding: 8px;

        &.__icon {
          padding: 8px 3px 8px 6px;
        }

        &.small {
          font-size: 12px;
          line-height: 12px;
        }

        &.disabled {
          cursor: not-allowed;
          opacity: 0.7;
        }
      }
    }
  }

  &-search {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #E4EBF3;

    margin: 6px 8px;

    div:first-child {
      padding: 6px 0;
    }

    &-icon {
      float: left;
      min-height: 28px;
      display: flex;
      align-items: center;
      padding-right: 4px;
      cursor: pointer;
    }

    &-field {
      padding: 6px 10px;

      input {
        border: none !important;
        outline: none !important;

        &:focus {
          outline: none !important;
        }
      }
    }
  }

  &-selector {
    display: flex;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #EBEFF6;
    flex-wrap: wrap;

    &-drop {
      width: 1px;
      height: 18px;
      background: #65D98C;
      box-shadow: 0px 0px 0px 2px rgba(101, 217, 140, 0.4);
      border-radius: 3px;
      margin: 5px 2px;
    }

    &-label {
      box-sizing: border-box;
      background-color: #EBEFF6;
      border-radius: 4px;
      padding: 2px 8px;

      display: flex;

      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.1px;
      line-height: 24px;

      &-name-dropdown {
        cursor: move;
      }

      &-trash {
        box-sizing: border-box;
        padding: 3px 4px 5px 4px;
        height: 24px;

        svg {
          cursor: pointer;
        }
      }
    }

    input.label-edit {
      border: none;
      background-color: transparent;
      width: auto;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.1px;    }
  }
}
