.login {
  &-wrapper {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding-top: 160px;

    background-color: #FFFFFF;
    background-image: url('./assets/login-bg.jfif');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  &-box {
    background: #FFFFFF;
    box-shadow: 3px 4px 10px rgba(32, 73, 132, 0.4);
    border-radius: 10px;

    width: 324px;

    padding: 24px;

    margin: 0 auto;

    &-header {
      display: flex;
      justify-content: center;
      margin-bottom: 32px;
    }

    &-content {
      h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 36px;
        line-height: 160%;
        text-align: center;
        color: #051A3C;
        margin: 0;
      }

      h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.1px;
        color: #9594B9;
        margin: 0;
      }
    }

    &-form {
      padding-top: 4px;

      .ant-form-item-label {
        display: none;
      }

      .ant-custom .ant-form-item-control .ant-form-item-control-input-content input {
        color: #98A1AF;
      }

      .ant-custom .ant-form-item-control .ant-form-item-control-input-content {
        padding: 8px 16px 8px 16px;
        border: 1px solid #D7DADE;
        border-radius: 4px;
      }

      .ui-btn.primary {
        width: 100%;
        justify-content: center;

        div {
          display: none;
        }
      }
    }
  }
}