.date-range {
  &-text {
    display: flex;
    cursor: pointer;
    height: 28px;

    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;

      letter-spacing: 0.15px;
      color: #051A3C;
    }

    &-icon {
      flex: 1;
      justify-content: right;
      display: flex;
      align-items: center;

      svg path {
        fill: #98A1AF;
      }
    }

    &:focus {
      &-icon {
        svg path {
          fill: #3463A7;
        }
      }
    }

    &:hover {
      &-icon {
        svg path {
          fill: #3463A7;
        }
      }
    }
  }

  &--calendar-wrapper {
    background: #FFFFFF;
    box-shadow: 3px 4px 10px rgba(32, 73, 132, 0.4);
    border-radius: 10px;

    padding: 5px;
    position: absolute;
    top: 100%;
    z-index: 10;
    display: none;

    margin-top: 4px;

    &.show {
      display: block;
    }

    .rdrMonthAndYearPickers select:hover{
      background-color: #FFFFFF;
    }

    .rdrMonthAndYearPickers select {
      padding: 6px 24px 6px 6px;
    }

    .rdrMonthAndYearWrapper {
      padding-top: 0;
      height: 40px;
    }

    .rdrMonth {
      width: 308px;
      padding: 10px;
      display: flex;
    }

    .rdrWeek {
      margin-top: 37px;
      width: 36px;

      .rdrWeekNumber {
        width: 36px;
        height: 42px;
        line-height: 36px;
        font-weight: bold;

        background-color: #585771;

        span {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.3px;
          color: #fff;
        }
      }
    }

    .rdrDays {
      padding-top: 6px;

      .rdrDay {
        margin: 0;
        padding: 0;
        line-height: 36px;
        height: 42px;
        width: 36px;
        color: #585771;

        span {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.3px;
          color: #051A3C;
        }
      }
    }

    .rdrDayDisabled .rdrDayNumber span {
      color: #aeb9bf;
    }

    .rdrWeekDays {
      border-bottom: 1px solid #D7DEE6;

      .rdrWeekDay {
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.3px;
        
        color: #585771;

        text-transform: capitalize;
      }
    }

    .rdrDayToday .rdrDayNumber span:after {
      display: none;
    }
  }
}

.rdrDay:has(.prefact) {
  background-color: #ffff00;
}

.rdrDay:has(.fastprefact) {
  background-color: #ffa500;
}

.rdrDay:has(.default) {
  background-color: #3caa3c;
}