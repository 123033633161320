@import '@styles/globals';

$z-index-header-fixed: 5;

.schedule-table-header {
  &_fixed {
    position: fixed;
    z-index: $z-index-header-fixed;
    border-bottom: 1px solid $color-schedule-table-border;
  }

  &_static {
    position: static;
  }
}
