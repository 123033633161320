.confirm-message {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  text-align: left;
  letter-spacing: 0.1px;

  padding: 12px 0;
}

.confirm-yes {
  background: #F15B5B;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: none;
  padding: 2px 8px;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  text-align: left;
  letter-spacing: 0.1px;

  color: #FFFFFF;

  &:focus{
    border: none;
    outline: none;
  }
}

.confirm-no {
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: none;
  padding: 2px 8px;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  text-align: left;
  letter-spacing: 0.1px;

  color: #3463A7;

  margin-right: 28px;

  &:focus{
    border: none;
    outline: none;
  }
}