
.userblock {
  display: inline-block;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;

  position: relative;

  box-sizing: border-box;

  &-subblock {
    display: flex;
  }

  &.logged-in {
    &:hover {
      background-color: #F8FAFF;
      cursor: pointer;
    }
  }

  .user {
    &-image {
      display: block;
      overflow: hidden;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      margin-right: 15px;
      background-color: #EBEFF6;

      img {
        max-width: 100%;
      }
    }

    &-menu {
      position: absolute;
      background-color: #FFFFFF;
      padding: 16px;
      border-radius: 0 0 8px 8px;
      width: 100%;
      margin: 11px 0 0 -10px;

      &-item {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;

        color: #98A1AF;

        padding: 4px;

        svg {
          fill: #98A1AF;
        }

        &:hover {
          color: #3B7BD7;

          svg {
            fill: #3B7BD7;
          } 
        }

        &-wrapper {
          display: flex;

          div {
            height: 30px;

            &:first-child {
              padding: 7px 16px 7px 0;
              line-height: 16px;
            }

            &:last-child {
              opacity: 0.8;
              line-height: 30px;
            }
          }
        }
      }
    }

    &-name {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;

      text-align: center;

      color: #3463A7;
    }
  }
}

.forgot-block {
  text-align: right;
  margin-top: 16px;
}