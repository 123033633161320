.collection-group {
  > .drop-flag {
    height: 1px;
    background: #65D98C;
    box-shadow: 0px 0px 0px 1px rgba(101, 217, 140, 0.4);
    border-radius: 3px;
    margin: 0 18px;
  }

  > .wrapper {
    flex: 1;
    display: flex;
    justify-content: left;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;

    border-radius: 8px;
    padding: 6px 0;

    cursor: pointer;

    &:hover {
      background-color: #F8FAFF;

      .icon-button {
        svg {
          fill-opacity: 1;
          stroke-opacity: 1;
        }
      }
    }

    &:active {
      background-color: #E8F1FF;

      .icon-button {
        svg {
          fill-opacity: 1;
          stroke-opacity: 1;
        }
      }
    }

    > .list {
      width: 24px;
      padding-right: 6px;
    }

    > .name {
      flex: 1;
      color: #3463A7;

      svg {
        float: left;
        margin: 3px 0;
        margin-right: 10px;
      }

      input {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;

        border: 1px solid #E8F1FF;
        padding: 0 5px;
        margin-left: -6px;
        border-radius: 8px;
        width: 100%;
        height: 24px
      }
    }

    > .actions {
      padding-right: 6px;
      width: 180px;

      > div {
        padding-left: 2px;
        display: flex;
        justify-content: right;
      }
    }

    svg {
      cursor: pointer;

      &.disabled {
        fill-opacity: 0.3;
      }
    }
  }

  textarea:focus, input:focus{
    outline: none;
  }
}

.collection-type {
  padding-left: 6px;

  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  margin-bottom: 12px;

  > .wrapper {
    padding: 6px 0;

    display: flex;
    justify-content: left;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;

    border-radius: 8px;

    cursor: pointer;

    &:hover {
      background-color: #F8FAFF;

      .icon-button {
        svg {
          fill-opacity: 1;
          stroke-opacity: 1;
        }
      }
    }

    &:active {
      background-color: #E8F1FF;

      .icon-button {
        svg {
          fill-opacity: 1;
          stroke-opacity: 1;
        }
      }
    }

    > .list {
      width: 24px;
      padding-right: 6px;
    }

    > .name {
      flex: 1;
      color: #3463A7;

      svg {
        float: left;
        margin: 3px 0;
        margin-right: 10px;
      }
    }

    > .actions {
      padding-right: 6px;
      width: 150px;

      > div {
        padding-left: 2px;
        display: flex;
        justify-content: right;
      }
    }

    svg {
      cursor: pointer;

      &.disabled {
        fill-opacity: 0.3;
      }
    }
  }

  > .children {
    padding: 0 0 10px 0;

    .type-bottom-line {
      border-top: 1px solid #EBEFF6;
      margin: 0 0 10px 30px;
    }
  }

  .icon-button {
    cursor: pointer;

    display: block;
    padding: 0;
    width: 24px;
    height: 24px;

    margin-left: 12px;

    svg {
      width: auto;
      height: auto;
      fill-opacity: 0.3;
      stroke-opacity: 0.3;
    }
  }
  
  .icon-button-op {
    svg {
      fill-opacity: 1 !important;
      stroke-opacity: 1 !important;
    }
  }

}

.collection-item {
  display: flex;
  justify-content: left;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;

  cursor: move;

  &-dragging {
    background: #F8FAFF;
    border: 2px solid #D9E0ED;
    box-sizing: border-box;
    border-radius: 8px;
    height: 36px;
  }

  .line {
    border-left: 2px solid rgba(52, 99, 167, 0.1);
    margin-left: 11px;
    width: 12px;
  }

  > .wrapper {
    flex: 1;
    display: flex;
    justify-content: left;
    border-radius: 8px;
    padding-left: 6px;


    &:hover {
      background-color: #F8FAFF;

      .icon-button {
        svg {
          fill-opacity: 1;
          stroke-opacity: 1;
        }
      }
    }

    &:active {
      background-color: #E8F1FF;

      .icon-button {
        svg {
          fill-opacity: 1;
          stroke-opacity: 1;
        }
      }
    }

    .name {
      flex: 1;
      color: #3463A7;
      padding: 6px 0 0 0;

      svg {
        float: left;
        margin: 3px 0;
        margin-right: 10px;
      }
    }

    .description {
      width: 120px;
      color: rgba(52, 99, 167, 0.2);
      padding: 6px 0;


      div {
        border-left: 1px solid rgba(52, 99, 167, 0.1);
        padding: 0 14px;
      }
    }

    .actions {
      padding: 6px 0;
      padding-right: 6px;
      width: 150px;

      > div {
        padding-left: 2px;
        display: flex;
        justify-content: right;
      }
    }
  }
}
