@media print {
  body {
    width: 8.5in !important;
  }

  .sidebar {
    display: none !important;
  }

  .header .row > div:not(:first-child) {
    display: none !important;
  }

  .content {
    margin: 0 !important;
    width: 8.5in !important;
    padding: 0 !important;

    .pdcard-wrapper {
      display: none !important;
    }

    .channels-chart {
      box-shadow: none;
      width: 8.5in !important;

      .highcharts-root {
        width: 8in !important;
      }
    }
  }

  .compare-audience-wrapper {
    display: none !important;
  }

  .compare-audience-chart {
    box-shadow: none;
    width: 8.5in !important;

    .highcharts-root {
      width: 8in !important;
    }
  }

  .audience-wrapper {
    .audience-title, .demographics-filters-wrapper {
      display: none !important;
    }

    .bars, .pie-charts {
      box-shadow: none;
      width: 8.5in !important;

      .highcharts-root {
        width: 8in !important;
      }
    }
  }
}