.pdcard-wrapper {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;

  .pdcard-title {
    background:#FFFFFF;
    padding: 20px;
    height: 84px;
    display: flex;

    & > div {
      align-items: center;
      align-self: center;

      &.title {
        flex: 1;
        padding: 10px;
        height: 44px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #051A3C;
      }

      &.template {
        width: 500px;
        margin-top: -12px;
        padding-right: 30px;
      }

      &:last-child {
        width: 300px;
        margin-top: -12px;
      }
    }
  }

  .pdcard-filters {
    background-color: #EBEFF6;
    padding: 4px 20px 20px 20px;

    .form-control {
      margin-top: 16px;
    }

    & > .two-columns {
      display: flex;

      & > div {
        flex: 1;

        &:first-child {
          margin-right: 8px;
        }

        &:last-child {
          margin-left: 8px;
        }
      }
    }
  }

  .pdcard-buttons {
    padding: 20px;
    background: #FFFFFF;
  }
}

.channels-chart {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  margin-top: 16px;
  height: 400px;
}
