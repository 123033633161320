@import '@styles/mixins';

.legend-item {
  padding: $spacing-1 $spacing-3;
  display: flex;
  align-items: center;
  min-width: 110px;

  &__marker {
    @include square(16px);

    margin-right: $spacing-2;
    border-radius: 50%;
    flex-shrink: 0;
  }

  &__title {
    @include text-general-accent;
  }
}
