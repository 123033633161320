@import '@styles/globals';

.dummy-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 0 auto;
  max-width: 380px;

  &__icons {
    display: flex;
    margin-bottom: $spacing-3;
    font-size: 20px;

    &-item {
      @include square($spacing-9);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $spacing-2;
      margin: 0 $spacing-1;
      border: 1px solid $color-schedule-table-border;
      border-radius: $spacing-1;
      background: $color-bg-schedule-table;
    }
  }

  &__title {
    @include text-content-layout-header;
    text-align: center;
  }

  &__text {
    @include text-schedule-dummy;
  }
}
