
.audience-wrapper {
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	position: relative;

	.audience-title {
		background:#FFFFFF;
		padding: 20px;
		display: flex;

		& > div {
			align-items: center;
			align-self: center;
	
			&.title {
				flex: 1;
				padding: 10px;

				font-family: 'Roboto';
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 24px;
				letter-spacing: 0.1px;
				color: #051A3C;

				.subtitle {
					font-family: 'Roboto';
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 20px;
					letter-spacing: 0.3px;
					color: #051A3C;
					padding: 5px 0 0 0;
				}
			}

			&:last-child {
				width: 400px;

				.form-control {
					margin-top: 0;
				}
			}

			&.title-button {
				display: contents;

				button {
					align-self: center;
				}				
			}

		}
	}

	.audience-method {
		span {
			font-weight: 500;
			color: #98A1AF;
		}
	}

	.audience-content {
		background: #FFFFFF;

		padding: 0 0 20px 0;

    .pie-charts {
      margin-top: 20px;
      padding: 0 20px;

      .pie-chart {
		position: relative;
        margin-bottom: 10px;
      }

      .chart-title {
        padding-left: 10px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.1px;
        color: #051A3C;
      }
    }

		.path {
			padding: 24px;

			justify-content: center;
			display: flex;

			span {
				opacity: 0.2;
				cursor: pointer;

				font-family: 'Roboto';
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 30px;
				letter-spacing: 0.1px;
				color: #051A3C;

				&.current {
					opacity: 1;
				}

				&.arrow {
					cursor: auto;
					padding: 0 10px;
					align-self: center;
					display: flex;
					opacity: 1;
				}
			}
		}

		.bars {
			width: 100%;
			position: relative;

			font-family: 'Roboto';
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 30px;
			letter-spacing: 0.1px;
			color: #051A3C;
			text-align: center;


			.back-button {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100px;
				cursor: pointer;
				margin-top: -15px;
				margin-left: -50px;

        color: #051A3C;
			}

			.is-loading {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100px;
				margin-top: -15px;
				margin-left: -50px;
				background-color: #FFFFFF;
			}
		}

		.pipes {
			width: 1000px;
			height: 500px;	
			margin: 0 auto;
		}
	}
}

.demographics-filters-wrapper {
	background: #EBEFF6;
	border-radius: 8px;
	padding: 16px;
	margin: 0 20px;

	.two-columns {
		display: flex;

		& > div {
			flex: 1;
		}
	}
  
	.ant-tabs-tab {
	  padding: 1px 8px;
	  border-radius: 4px;
	  color: #585771;
	  align-items: center;
	  letter-spacing: 0.1px;
	  border: none;
	  background: #F8FAFF;
  
	  &.ant-tabs-tab-active {
		background: #3463A7;
		border: none;
  
		.ant-tabs-tab-btn {
		  color: #FFFFFF;
		  text-shadow: none;
		}
	  }
	}
  
	.date-range-text p {
	  font-weight: 500;
	}
  
	.ant-tabs-top > .ant-tabs-nav {
	  margin: 0;
	}
  
	.ant-tabs-ink-bar {
	  display: none;
	}
  
	.ant-tabs-tab + .ant-tabs-tab {
	  margin: 0 0 0 12px;
	}
  
	hr.split {
	  border: 1px solid #D9E0ED;
	  margin: 16px 0;
	}
  
	.premier-wrapper {
	  margin-top: 16px;
  
	  .ant-radio-input {
      outline: none;
    
      &:focus {
        outline: none;
      }
	  }
  
	  .ant-radio-inner {
      &:focus {
        outline: none;
      }
    
      border-color: white;
      background: transparent;
	  }
  
	  .ant-radio-checked .ant-radio-inner{
		  border-color: white;
	  }
	  
	  .ant-radio-checked .ant-radio-inner:after{
      background-color: #3463A7;
      transform: scale(0.75);
	  }
	  
	  .ant-radio:hover .ant-radio-inner {
		  border-color: #3463A7 ;
	  }
  
	  span {
		  color: #9594B9;
	  }
	}
}  