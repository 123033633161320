.export-button {
  position: absolute;
  z-index: 1000;
  padding: 10px;
  top: 0;
  right: 0;
}


.export-list {
  padding: 5px;
  position: absolute;
  display: block;
  z-index: 1001;
  right: 20px;
  top: 20px;

  background-color: #FFFFFF;
  box-shadow: 3px 4px 10px rgba(32, 73, 132, 0.4);
  border-radius: 4px;
  box-sizing: border-box;
}

.list-wrapper {
  display: flex;
  flex-direction: column;

  .list-item {
    display: flex;
    flex-direction: row;
    padding-right: 6px;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: 24px;

    cursor: pointer;

    div {
      color: #3463A7;
  
      padding: 6px;
      white-space: nowrap;

      &.__icon {
        padding: 8px 3px 8px 6px;
      }

      &.small {
        font-size: 12px;
        line-height: 12px;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.7;
      }
    }
  }
}

@media print {
  .export-button {
    display: none !important;
  }

  .export-list {
    display: none !important;
  }
}