@import '@styles/globals';

.schedule-table-cell-stats {
  display: flex;
  width: 100%;
  align-items: flex-start;

  &__item {
    padding-right: $spacing-2;

    &:last-child {
      padding-right: 0;
    }

    &_title {
      flex-grow: 1;
    }

    &_ratings {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      opacity: 0;

      &-opaque {
        opacity: 1;
      }
    }
  }

  &__text {
    font-size: inherit;
    line-height: inherit;

    &_title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      word-break: break-all;
      hyphens: auto;
      overflow: hidden;
    }
  }
}
