@media only screen and (max-width: 1024px) {
    
  .dashboard {
    margin: 80px auto 20px auto;
    width: 740px;

    &-wrapper {
      background: #FFFFFF;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      border-radius: 4px;  
    }
  
    &-title {
      font-size: 12px;
      letter-spacing: 0.1px;
      line-height: 16px;
      padding: 10px;
    }
  
    &-results {
      margin-top: 12px;
      margin-bottom: 12px;
  
      &-wrapper {
        width: 458px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
  
        padding-bottom: 12px;
  
        .seek-bar {
          padding: 16px 8px 4px 12px;

          &-line {
            padding: 11px 11px 5px 11px;
          }
        }
      }

      .rc-slider-handle {
        border: solid 2px #3B7BD7;
        opacity: 1;
        margin-top: -4px;
      }

      .rc-slider-handle:before {
        top: -18px;
        left: -15px;
        font-size: 10px;
        line-height: 12px;
      }
  
      .intervals-wrapper {
        & > div {
          font-weight: 400;
          font-size: 9px;
          line-height: 14px;
        }
      }
  
      .chart {
        margin-top: 8px;
        margin-left: 0;
        margin-right: 8px;
  
        & > div:first-child {
          font-size: 10px;
          line-height: 14px;
          padding-bottom: 16px;
        }
      }
    }
  
    .videos {
      display: flex;
      gap: 8.4px;
      width: 458px;
      flex-wrap: wrap;
      padding: 8.4px;
  
      > div {
        width: 216px;
        height: 121px;
        border-radius: 8px;
      }

      .spinner {
        left: calc(50% - 8px);
        top: calc(50% - 8px);
      }

      .player {
 
        .video-stats {
          width: 116px;
          height: 120px;
          top: 0;

          &-left {
            left: 233px;
          }
  
          &-right {
            left: -133px;
          }
  
          &-channel {
            font-size: 10px;
            line-height: 14px;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
            padding: 2px 4px;
  
            cursor: pointer;
          }
  
          &-volume {
            svg {
              width: 18px;
              height: 18px;
            }
          }
  
          &-list {
            display: block;
            padding: 2px 4px;
          }
          
          .stat-info {
            font-size: 10px;
            line-height: 14px;
 
            margin-top: 4px;
  
            div:first-child {
              margin-right: 2px;
  
              svg {
                top: 0px;
              }
            }
  
            div:last-child {
              margin-left: 3px;
            }
          }
        }
      }
    }
  
    .filters {
      display: flex;
      width: 458px;
      gap: 5px;
      padding: 8px;
      flex-direction: row;
      flex-wrap: wrap;

      &-statistics {
        width: 458px;
      }
  
      &-audience {
        width: 160px;
      }
  
      &-base {
        width: 160px;
      }

      &-date {
        width: 150px;
      }
  
      &-interval {
        width: 122px;
      }
    }
  
    .play-button {
      cursor: pointer;
      margin-right: 4px;
    }

    .more-stats {
      left: 466px;
      width: 116px;
      margin-top: 12px;
  
      & > div {
        margin-bottom: 8px;
      }
  
      &-channel {
        font-size: 10px;
        line-height: 14px;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        padding: 2px 4px;
  
        .trash {
          height: 18px;
          width: 18px;
          padding: 3px 4px;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
  
      &-channel-select {
        font-size: 10px;
        line-height: 14px;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        padding: 2px 4px;
      }
  
      &-list {
        padding: 2px 4px;
      }
  
      .stat-info {
        font-size: 10px;
        line-height: 14px;

        margin-top: 4px;

        div:first-child {
          margin-right: 2px;

          svg {
            top: 0px;
          }
        }

        div:last-child {
          margin-left: 3px;
        }
      }
    }
  }
  
  .form-control {
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding: 4px 8px;
    margin-top: 10px;
    width: 100%;
  
    .multiselect-dropdown {
      margin-top: 4px;
    }
  
    label {
      top: -8px;
      left: 12px;
      padding: 0 4px;
      font-size: 10px;
      line-height: 14px;
      height: 12px;
  
      span {
        line-height: 14px;
      }
    }
  }
}