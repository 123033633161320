@import '@styles/globals';
$z-index-table-content: 0;
$z-index-table-tabs: 5;
$z-index-table-legend: 5;
$page-header-height: 52px;

.schedule-table {
  position: relative;
  z-index: $z-index-table-content;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__tabs {
    position: sticky;
    top: $page-header-height;
    left: 0;
    z-index: $z-index-table-tabs;
    background: $color-bg-page;
    border-top-left-radius: $border-radius-2;
    border-top-right-radius: $border-radius-2;
    border-top: 1px solid $color-schedule-table-border;
    border-left: 1px solid $color-schedule-table-border;
    border-right: 1px solid $color-schedule-table-border;
    overflow: auto;
  }

  &__content {
    z-index: $z-index-table-content;
  }

  &__legend {
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: $z-index-table-legend;
    padding-left: 0;
    border-bottom-left-radius: $border-radius-2;
    border-bottom-right-radius: $border-radius-2;
    border: 1px solid $color-schedule-table-border;
  }
}
