.calendar-heatmap-wrapper {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 16px;

  .ant-tabs-top > .ant-tabs-nav::before {
    border: none;
  }

  .ant-tabs-tab {
    padding: 1px 8px;
    border-radius: 4px;
    color: #585771;
    align-items: center;
    letter-spacing: 0.1px;
    border: none;
    background: #F8FAFF;

    &.ant-tabs-tab-active {
      background: #3463A7;
      border: none;

      .ant-tabs-tab-btn {
        color: #FFFFFF;
        text-shadow: none;
      }
    }
  }

  .date-range-text p {
    font-weight: 500;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 12px;
  }

  hr.split {
    border: 1px solid #D9E0ED;
    margin: 16px 0;
  }

  .premier-wrapper {
    margin-top: 16px;

    .ant-radio-input {
      outline: none;

      &:focus {
        outline: none;
      }
    }

    .ant-radio-inner {
      &:focus {
        outline: none;
      }

      border-color: white;
      background: transparent;
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: white;
    }

    .ant-radio-checked .ant-radio-inner:after {
      background-color: #3463A7;
      transform: scale(0.75);
    }

    .ant-radio:hover .ant-radio-inner {
      border-color: #3463A7;
    }

    span {
      color: #9594B9;
    }
  }

  .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

}

