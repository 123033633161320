
.dashboard {
  width: 1408px;

  display: flex;
  flex-direction: column;
  align-items: center;

  &-wrapper {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: relative;
  }

  &-title {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.1px;
    line-height: 24px;
    padding: 16px;
    border-bottom: 1px solid #E4EBF3;
  }

  &-results {
    margin-top: 24px;
    margin-bottom: 24px;

    &-wrapper {
      box-sizing: border-box;
      width: 868px;
      background: #FFFFFF;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      padding-bottom: 24px;

      .seek-bar {
        padding: 16px 16px 16px 20px;

        display: flex;

        &-wrapper {
          flex: 1;
        }

        &-line {
          padding: 11px 14px 5px 14px;
        }
      }

      .dashboard-title {
        display: flex;
        align-self: center;

        .player-bottons {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          .prev-button {
            cursor: pointer;
          }

          .next-button {
            cursor: pointer;
          }

          .speed-button {
            cursor: pointer;
            position: relative;
            width: 36px;
            height: 36px;

            & > span {
              position: absolute;
              top: 0px;
              font-size: 11px;
              font-weight: 600;
              line-height: 36px;
              width: 36px;
              text-align: center;
              color: #3b7bd7;
              letter-spacing: -1px;
            }
          }
        }
      }
    }

    .ant-tabs-nav-wrap {
      margin-left: 30px;
      margin-right: 30px;

      .ant-tabs-tab-btn {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.1px;
        line-height: 24px;
      }
    }

    .rc-slider-handle {
      border: solid 2px #3B7BD7;
      opacity: 1;
      margin-top: -4px;
    }

    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
      border-color: #3B7BD7;
      box-shadow: 0 0 0 3px #3B7BD7;
    }

    .rc-slider-handle:focus-visible {
      border-color: #3B7BD7;
      box-shadow: 0 0 0 3px #3B7BD7;
    }

    .rc-slider-handle-click-focused:focus {
      border-color: #3B7BD7;
      box-shadow: unset;
    }

    .rc-slider-handle:hover {
      border-color: #3B7BD7;
    }

    .rc-slider-handle:active {
      border-color: #3B7BD7;
      box-shadow: 0 0 3px #3B7BD7;
      cursor: -webkit-grabbing;
      cursor: grabbing;
    }

    .rc-slider-track {
      height: 6px;
      background: #3B7BD7;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      border-radius: 24px;
    }

    .rc-slider-rail {
      background: #E4E7ED;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      border-radius: 24px;
    }

    .rc-slider-handle:before {
      content: attr(aria-valuetext);
      position: absolute;
      top: -20px;
      left: -18px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }

    .intervals-wrapper {
      display: flex;
      justify-content: flex-start;

      & > div {
        flex: 1;
        text-align: center;

        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        &:first-child {
          flex: 0.75;
          text-align: left;
        }

        &:last-child {
          flex: 0.75;
          text-align: right;
        }
      }
    }

    .chart {
      margin-top: 16px;
      margin-left: 0;
      margin-right: 17px;

      & > div:first-child {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        padding-bottom: 16px;
      }
    }
  }

  .videos {
    display: flex;
    gap: 16px;
    width: 868px;
    flex-wrap: wrap;
    padding: 16px;

    > div {
      width: 410px;
      height: 230px;
      border-radius: 8px;
    }

    .spinner {
      position: absolute;
      left: calc(50% - 16px);
      top: calc(50% - 16px);

      svg {
        fill: #3B7BD7;
      }
    }

    .noise {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;

      display: flex;

      & > div {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .player {
      background: #000000;
      width: 100%;
      height: 100%;
      position: relative;

      .video-stats {
        width: 220px;
        height: 230px;
        position: absolute;
        top: 0;

        &-left {
          left: 442px;
        }

        &-right {
          left: -252px;
        }

        &-channel {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;

          background: #3463A7;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          padding: 3px 8px;

          cursor: pointer;
        }

        &-volume {
          position: absolute;
          bottom: 0;
          cursor: pointer;

          svg {
            width: 24px;
            height: 24px;
          }
        }

        &-list {
          display: block;
          padding: 3px 8px;
        }

        .stat-info {
          display: flex;
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          color: #3463A7;

          margin-top: 8px;

          div:first-child {
            margin-right: 4px;

            svg {
              position: relative;
              top: -2px;
            }
          }

          div:last-child {
            font-weight: 300;
            margin-left: 5px;
          }
        }
      }
    }
  }

  .filters {
    display: flex;
    width: 868px;
    gap: 10px;
    padding: 16px;

    .multiselect-selector {
      background-color: #ffffff;
    }

    .multiselect-selector-label {
      background-color: #ffffff;
    }

    &-statistics {
      width: 304px;
    }

    &-audience {
      width: 204px;
    }

    &-base {
      width: 152px;
    }

    &-date {
      width: 170px;

      .form-control {
        height: 46px;
      }
    }

    &-interval {
      width: 130px;
    }
  }

  .play-button {
    cursor: pointer;
    margin-right: 8px;

    &[disabled] {
      svg {
        opacity: 0.5;

        &:hover {
          fill: #3b7bd7 !important;
    
          path {
            fill: #3b7bd7 !important;
          }
        }
      }
    }
    
    svg:hover {
      fill: #1769AA !important;

      path {
        fill: #1769AA !important;
      }
    }
  }

  .more-stats {
    position: absolute;
    left: 883px;
    width: 220px;
    margin-top: 24px;

    & > div {
      margin-bottom: 16px;
    }

    &-channel {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;

      background: #3463A7;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      padding: 3px 8px;

      cursor: pointer;

      position: relative;

      .trash {
        color: inherit;
        position: absolute;
        right: 0;
        top: 0;

        height: 30px;
        width: 30px;
        padding: 6px 8px;

        svg {
          color: inherit;
          fill: currentColor !important;

          path {
            color: inherit;
            fill: currentColor !important;
          }
        }
      }
    }

    &-channel-select {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;

      background: #3463A7;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 3px 8px;

      cursor: pointer;
    }

    &-list {
      display: block;
      padding: 3px 8px;
    }

    .stat-info {
      display: flex;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #3463A7;

      margin-top: 8px;

      div:first-child {
        margin-right: 4px;

        svg {
          position: relative;
          top: -2px;
        }
      }

      div:last-child {
        font-weight: 300;
        margin-left: 5px;
      }
    }
  }
}

.ant-modal-content {
  border-radius: 8px;

  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }
}

.form-control {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 12px;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  .multiselect-dropdown {
    margin-top: 4px;
  }

  label {
    position: absolute;
    top: -10px;
    left: 12px;
    padding: 0 4px;
    background-color: #FFFFFF;
    width: auto;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.15px;
    color: #98A1AF;
    height: 12px;
    margin: 0;

    span {
      line-height: 16px;
    }
  }

  .ant-picker {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-appearance: none !important;
    width: 100%;
    padding: 0;
  }
  
  .ant-picker-focused {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-appearance: none !important;
  }
  
  .ant-picker-input > input {
    padding: 0;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: 28px;
  }  
}
