.and-or {
    display: flex;
    flex-direction: row;
    border-radius: 4px;

    div {
        padding: 2px 8px;

        color: #3463A7;
        background-color: #FFFFFF;
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        letter-spacing: 0.1px;

        cursor: pointer;

        &.selected {
            color: #FFFFFF;
            background-color: #3463A7;
        }

        &:first-child {
            border-radius: 4px 0 0 4px;
        }

        &:last-child {
            border-radius: 0 4px 4px 0;
        }
    }
   
    margin-right: 20px;
}