.compare-audience {
  /**
   *todo make reusable panel component with hovers, pointers, animations
   */
  &-wrapper {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: relative;
  }

  &-title {
    background:#FFFFFF;
    padding: 20px;
    display: flex;
    border-radius: 8px;

    & > div {
      align-items: center;
      align-self: center;

      &.title {
        flex: 1;
        padding: 10px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #051A3C;
      }

      &.template {
        width: 500px;
        margin-top: -12px;
        padding-right: 30px;

      }

      &:last-child {
        width: 300px;
        margin-top: -12px;
      }
    }
  }

  &-method {
    padding-bottom: 16px;
    border-bottom: 1px solid #E4EBF3;
    display: flex;

    &-title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #98A1AF;
      margin-right: 16px;
    }

    span {
      font-weight: 500;
      color: #98A1AF;
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: #3463A7;
    }

    .ant-radio-inner, .ant-radio-checked {
      &:after {
        background-color: #3463A7;
      }
    }
  }

  &-events {
    display: flex;
    gap: 16px;

    & > div {
      flex: 1;
      max-width: calc(50% - 8px);
    }
  }

  &-filters {
    border-top: 1px solid #E4EBF3;
    background: #FFFFFF;
    padding: 20px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    .form-control {
      margin-top: 16px;
    }

    .target-control {
      height: 44px;
      border: 1px solid #D7DADE;
      border-radius: 4px;
      margin-bottom: 16px;
      padding: 8px 16px;

      label {
        position: absolute;
        top: -10px;
        left: 12px;
        padding: 0 4px;
        background-color: #FFFFFF;
        width: auto;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.15px;
        color: #98A1AF;
        height: 12px;
        margin: 0;
      }
    }

    .buttons {
      margin-top: 16px;
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &-chart {
    margin-top: 20px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: relative;
    background: #FFFFFF;

    & > div > div {
      margin: 0 auto;
    }
  }

  input {
    border-color: black;
    border-width: 2px;

  }

  .qual-reach {
    & > span {
      padding-right: 8px;
      color: #9594B9;
    }

    & > input {
      width: 100px;
      font-weight: 500;
    }
  }
}
