.content {
  margin-left: 260px;
  padding: 20px;
  padding-top: 75px;
  background-color: #F8FAFB;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &.mini {
    margin-left: 75px;
  }
}

.filter-buttons-wrapper {
  display: flex;

  .btn {
    margin-right: 40px;
  }
}

.btn-filter {
  border-radius: 25px !important;
  border: 1px solid #E4E4E4 !important;
  font-weight: 600 !important;
  padding: 10px 20px !important;
  position: relative;

  span {
    padding: 10px 20px;
    margin: -10px -20px;
  }

}

.table {
  td {
    .MuiFormControlLabel-root {
      margin-bottom: 0 !important;
    }

    .MuiIconButton-root {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.calendar-component {
  position: absolute;
  top: calc(100% + 15px);
  left: 0;
  background: #ffffff;
  padding: 15px;
  z-index: 100;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #E4E4E4;
  border-radius: 20px;
  display: flex;

  .react-datepicker-wrapper {
    input {
      margin-right: 15px;
      max-width: 100px;
      border: 1px solid #E4E4E4;
      padding: 2px 5px;
      font-size: 14px;
      text-align: center;
    }

    &:last-of-type {
      input {
        margin-right: 0;
      }
    }
  }
}

.btn-chart-type  {
  border: 1px solid #E4E4E4!important;
  &.active {
    background: #E4E4E4!important;
  }
}
