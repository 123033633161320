@use "sass:color";
@import "@styles/mixins";

.tab {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  padding: $spacing-3 $spacing-4;
  background-color: transparent;

  @include text-tabbar;

  &__inner {
    padding-right: 10px;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;

    @include transition(color);

    &:hover {
      color: $color-text-active;
    }
  }

  &__close {
    &-btn {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-icon-cross-bg;
      border-radius: 50%;
      cursor: pointer;

      @include transition(background-color);

      &:hover {
        background-color: color.scale($color-icon-cross-bg, $lightness: -10%);
      }
    }

    &-icon {
      color: $color-icon-cross;
      line-height: 0;
    }
  }
}
