.average-controls-wrapper {
  padding: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

  .ant-tabs-top > .ant-tabs-nav::before {
      border: none;
  }

  .ant-tabs-tab {
    padding: 1px 8px;
    border-radius: 4px;
    color: #585771;
    letter-spacing: 0.1px;
    border: none;
    background: #EBEFF6;

    &.ant-tabs-tab-active {
      background: #3463A7;
      border: none;

      .ant-tabs-tab-btn {
        color: #FFFFFF;
        text-shadow: none;
      }
    }
  }

  .ant-tabs-nav {
    padding: 16px;
    background: #FFFFFF;
    border-top: 1px solid #D9E0ED;
  }

  .ant-tabs-content-holder {
    background: #EBEFF6;
    padding-bottom: 16px;
    border-radius: 0px 0px 8px 8px;
  }

  .ant-switch {
    width: 62px;
    height: 28px;
    background-color: #FFFFFF;

    .ant-switch-handle {
      position: absolute;
      top: 2px;
      left: 2px;
      width: 24px;
      height: 24px;
      transition: all 0.2s ease-in-out;

      &::before {
        border-radius: 12px;
        background: #EBEFF6;
        box-shadow: none;
      }
    }

  }

  .ant-switch-checked {
    background-color: #3463A7;

    .ant-switch-handle::before {
      background: #FFFFFF;
    }
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 24px - 2px);
  }

  .switch {
    display: flex;

    span {
      height: 28px;
      line-height: 28px;
      padding-left: 8px;
      color: #9594B9;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 12px;
  }

  .crow {
    display: flex;
    margin-bottom: 16px;
  
    &:last-child {
      margin-bottom: 0;
    }
  
    .ccol {
      flex: 1;
      margin-left: 16px;
  
      &:last-child {
        margin-right: 16px;
      }
    }
  }

  .add-button {
    justify-content: flex-end;
    display: flex;

    & > div {
      display: flex;
      cursor: pointer;
      align-items: center;
      
      span {
        padding-right: 20px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #9594B9;
      }
    }

    .highlight {
      span {
        color: #3463A7;
      }
      
      svg {
        fill: #3463A7 !important;

        path {
          fill: #3463A7 !important;
        }
      }
    }
  }
}

.average-collection-actions {
  .title-block {
    padding: 20px;
    display: flex;
    background: #F8FAFB;

    & > div.title {
      flex: 1 1;
      padding: 10px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.1px;
    }
  }
}