.channels-info {
  width: 100%;
  
  .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    
    letter-spacing: 0.1px;
    
    color: #051A3C;

    padding-left: 25px;
  }

  .wrapper {
    padding: 17px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .box {
      width: 33.33%;
      display: flex;
      flex-direction: column;
      padding: 8px;

      div {
        font-family: 'Roboto';
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #000000;

        &:first-child {
          font-weight: 700;
          padding-bottom: 8px;
        }

        &:last-child {
          font-weight: 400;
        }
      }
    }
  }
}

.issued-filters {
  padding: 0 16px;

  .button {
    display: flex;
    justify-content: flex-end;
    padding: 16px 0;
  }
}

.program-issued {
  .form-control {
    margin-top: 0;
  }
}

.issued-wrapper {
  background: #EBEFF6;
  border-radius: 0px 0px 8px 8px;
  padding: 16px;

  .issued-content {
    width: 100%;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 8px;
    gap: 8px;
    display: flex;
    flex-wrap: wrap;

    .issued {
      background: #EBEFF6;
      border-radius: 4px;

      a {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        display: flex;
        align-items: center;
        letter-spacing: 0.1px;

        color: #585771;

        padding: 3px 8px;
      }
    }
  }
}

.search-wrapper {
  display: flex;
  padding: 0 20px;
  gap: 20px;

  div:last-child {
    padding-top: 12px;
  }
}

.filters-white-wrapper {
  background: #FFFFFF;
  display: flex;
  padding: 0 20px 0 20px;
  gap: 20px;

  > div {
    flex: 1;
  }

  > div:first-child {
    flex: 2;
  }
}

.chart-wrapper {
  background: #FFFFFF;
  padding: 0;
  position: relative;

  .title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    
    letter-spacing: 0.1px;
    
    color: #051A3C;

    padding-left: 25px;
  }

  .is-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    background-color: rgba(255, 255, 255, 0.5);

    div {
      flex: 1;
      padding-top: 20px;
      text-align: center;
    }
  }
}