@mixin media($width) {

    @media (max-width: $width) {
      @content;
    }
  }
  
  .dateFilter {
    //display: flex;
    //align-items: center;
  
    @include media("1480px") {
      flex-wrap: wrap;
    }
  
    &--presets {
      display: flex;
      flex-wrap: wrap;
    }
  
    div.col {
      align-self: center;
    }
  
    &--interval {
      //margin-top: -5px;
      //margin-left: 7rem;
      @include media("1480px") {
        //margin-left: 1rem;
      }
  
      @include media("1428px") {
        //margin-left: 0;
      }
  
      @include media("1409px") {
        margin-left: 0;
        //margin-top: 1rem;
      }
    }
  
    .btn {
      background: rgba(0, 0, 0, 0.04);
      color: rgba(0, 0, 0, 0.5);
      border-radius: 16px;
      margin-right: 15px;
      margin-bottom: 2px;
  
      @include media("1409px") {
        padding: 0.2rem 0.65rem;
      }
  
      &.active {
        color: #333;
        //background: #979797;
        background: #dadada;
      }
    }
  
    &--calendar {
      display: inline-block;
      position: relative;
      margin-top: 5px;
  
      &-informr {
        display: inline-flex;
        align-items: center;
        //margin-left: 60px;
        cursor: pointer;
  
        span {
          margin: 0 10px;
          white-space: nowrap;
        }
      }
  
      &-wrapper {
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
        border: 1px solid #E4E4E4;
        background-color: #fff;
        padding: 5px;
        position: absolute;
        top: 100%;
        z-index: 10;
        display: none;
  
        &.show {
          display: block;
        }
      }
    }
  }
  