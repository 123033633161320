.data-table {
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow-x: auto;

  &-title-wrapper {
    display: flex;
  }

  &-buttons {
    justify-content: flex-end;
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 24px;
  }

  &-info {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1px;

    color: #051A3C;
    padding: 16px 24px;
  }

  .table {
    border-collapse: collapse;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;

    color: #0C385C;
  }

  .table th, .table td {
    border: 1px solid #E4EBF3;
    font-weight: 400;
    padding: 8px 24px;
  }
  
  .table th:first-child, .table td:first-child {
    border-left: none;
  }

  .table th:last-child, .table td:last-child {
    border-right: none;
  }

  .chakra-form-control {
    margin: 20px 0;
  }

  .composition-row {
    h2 {
      font-size: 18px;
      font-weight: bold;
    }
  }

  &-title {
    font-size: 18px;
    padding: 10px;
  }

  th.desc {
    border-bottom: 5px solid #3463A7;
  }
  
  th.asc {
    border-top: 5px solid #3463A7;
  }
}