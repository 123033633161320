.ant-custom {
  .ant-form-item {
    position: relative;
    margin: 20px 0;
  }
  
  .ant-form-item-label {
    z-index: 10; 
    position: absolute;
    height: 18px;
    top: -9px;
    left: 14px;
    background-color: #FFFFFF;
    padding: 0 2px !important;
    display: block;

    > label {
      display: block;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;

      letter-spacing: 0.15px;

      color: #3463A7;
    }
  }

  .ant-form-item-control {
    .ant-form-item-control-input-content {
      padding: 9px 16px 7px 16px;

      border: 1px solid #3463A7;
      border-radius: 8px;
  
      .ant-input-password {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        -webkit-appearance: none !important;
        padding: 0;
      }

      input {
        padding: 0;

        color: #051A3C;

        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        
        letter-spacing: 0.15px;

        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        -webkit-appearance: none !important;

        &:focus {
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
          -webkit-appearance: none !important;
        }

        &:hover {
          border: none !important;
          outline: none !important;
          box-shadow: none !important;
          -webkit-appearance: none !important;
        }
      }
    }

    .ant-form-item-explain-error {
      padding: 2px 16px;
    }
  }
  
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    margin: 0;
    content: '';
  }
  
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin: 0 0 0 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}