@import '@styles/globals';

$z-index-program-hover: 1;
$z-index-dashed-line: 4;

.schedule-table-cell-program {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $color-bg-active-cell;
  padding: $spacing-0_5 $spacing-2;

  &:hover {
    z-index: $z-index-program-hover;
    text-decoration: underline;
  }

  &_label {
    &::before {
      content: '';
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      width: 2px;
    }

    &_audienceX::before {
      background: $color-schedule-event-group-audience-x;
    }

    &_audienceY::before {
      background: $color-schedule-event-group-audience-y;
    }
  }

  &_dashed {
    &::after {
      display: none;
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      z-index: $z-index-dashed-line;
      border-bottom: 1px dashed
        scale-color($color-schedule-table-border, $blackness: 50%);
    }

    &-intersects-interval-start {
      &::after {
        display: block;
        top: 0;
      }
    }

    &-intersects-interval-end {
      &::after {
        display: block;
        top: 100%;
      }
    }
  }
}
