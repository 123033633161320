@import '@styles/globals';

.schedule-table-cell-timeslot {
  border-bottom: 1px solid $color-schedule-table-border;
  padding: $spacing-1 $spacing-2;
  background-color: $color-bg-page;

  &_before-stub {
    border-bottom: 1px dashed $color-schedule-table-border;
  }
}

.schedule-table-cell-timestub {
  background-color: $color-timestub;
  border-bottom: 1px dashed $color-schedule-table-border;
}
