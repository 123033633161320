@import './styles/print.scss';
@import 'antd/dist/antd.css';
@import './node_modules/bootstrap/scss/bootstrap';
@import 'react-toastify/dist/ReactToastify.css';
@import 'components/DateRange/styles.scss';
@import 'components/DateRange/theme/default.scss';
@import 'components/UI/DateRange.scss';

html, body {
  height: 100%;
}

#root {
  min-height: 100%;
  background-color: #F8FAFB;
}

.text-right {
  text-align: right !important;
}

.wrapper {
  position: relative;
  height: 100%;
}

.hidden {
  display:none;
}

.graph-error {
  font-weight: bold;
  position: absolute;
  z-index: 9;
  opacity: 0.8;
  width: 100%;
  top: 45%;
  justify-content: center;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
}

header.chakra-modal__header {
  font-family: 'Roboto', sans-serif !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 36px !important;
  line-height: 160% !important;

  color: #051A3C !important;
}

span {
  font-family: 'Roboto', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.15px;

  color: #051A3C;
}

h3 {
  font-family: 'Roboto', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.1px;
  color: #051A3C;
}

div.block-message {
  padding: 16px 0;

  font-family: 'Roboto', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;

  letter-spacing: 0.1px;

  color: #051A3C;
}

.react-date-picker__calendar {
  .react-calendar {
    background: #FFFFFF;
    box-shadow: 3px 4px 10px rgba(32, 73, 132, 0.4);
    border-radius: 10px;
    border: none;
    padding: 16px;
    width: 284px;

    span {
      color: #585771;
      text-transform: capitalize;
    }

    abbr {
      font-family: 'Roboto', sans-serif !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.3px;

      color: #585771;
    }

    .react-calendar__month-view__weekdays {
      border-bottom: 1px solid #D7DEE6;

      abbr {
        text-decoration-style: unset;
        text-decoration-line: none;
        text-transform: capitalize;
      }
    }

    .react-calendar__navigation {
      height: 30px;

      button {
        font-size: 16px;
        color: #D5E0E6;
        min-width: 30px;
      }
    }

    .react-calendar__tile {
      padding: 8px 4px;

      &--active {
        background-color: #F15B5B !important;
        border-radius: 50% !important;

        abbr {
          color: #FFFFFF;
        }
      }
    }
  }
}

.form-control {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  min-height: 44px;
  padding: 7px 16px;
  margin-top: 12px;
  width: 100%;

  .multiselect-dropdown {
    top: 100%;
    margin-top: 4px;
  }

  label {
    position: absolute;
    top: -10px;
    left: 12px;
    padding: 0 4px;
    background-color: #FFFFFF;
    width: auto;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.15px;
    color: #98A1AF;
    height: 12px;
    margin: 0;

    span {
      line-height: 16px;
    }
  }

  input {
    padding: 0;

    color: #051A3C;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.15px;

    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-appearance: none !important;

    &:focus {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      -webkit-appearance: none !important;
    }

    &:hover {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      -webkit-appearance: none !important;
    }
  }
}


.card-wrapper {
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	position: relative;

  & > div:first-child {
    border-radius: 8px 8px 0 0;
  }

  & > div:last-child {
    border-radius: 0 0 8px 8px;
  }

  & > div.no-radius {
    border-radius: 0;
  }

	.card-title {
		background:#FFFFFF;
		padding: 20px;
		display: flex;

		& > div {
			align-items: center;
			align-self: center;

			&.title {
				flex: 1;
				padding: 10px;

				font-family: 'Roboto';
				font-style: normal;
				font-weight: 500;
				font-size: 20px;
				line-height: 24px;
				letter-spacing: 0.1px;
				color: #051A3C;
			}

      &.controls {
        align-items: right;
        align-self: right;
        width: 180px;
      }
		}
	}

  .card-title-full {
		background:#FFFFFF;
    padding: 20px 0;
  }

  .card-content {
    border-top: 1px solid #E4EBF3;
    background: #FFFFFF;

		padding: 20px 0;
  }
}

.filters-wrapper {
  background: #EBEFF6;
  border-radius: 8px;
  padding: 16px;

  .two-columns {
    display: flex;

    & > div {
      flex: 1;

      &:first-child {
        margin-right: 8px;
      }

      &:last-child {
        margin-left: 8px;
      }
    }
  }
}
