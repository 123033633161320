.event-wrapper {
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  margin-bottom: 8px;

  span {
    margin: 4px;
    padding: 2px 8px;
    border-radius: 4px;
    color: #585771;
    letter-spacing: 0.1px;
    border: none;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    background: #EBEFF6;
  }

  .sort {
    padding: 8px 0;

    cursor: move;

    & > div {
      height: 100%;
      border-right: 1px solid #D9E0ED;
      padding: 0 8px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      & > svg {
        margin: 3px 0;
      }
    }
  }

  .info {
    padding: 4px 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > div {
      display: flex;

      span:first-child {
        background: #3463A7;
        color: #FFFFFF;
      }
    }
  }

  .group {
    padding: 8px 0;
    flex: 1;

    & > div {
      height: 100%;
      border-left: 1px solid #D9E0ED;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 4px;

      & > div {
        display: flex;
      }
    }
  }

  .buttons {
    padding: 8px 0;

    & > div {
      height: 100%;
      border-left: 1px solid #D9E0ED;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      padding: 0 8px;

      .button {
        padding: 10px;
        cursor: pointer;
      }
    }
  }
}