@import '@styles/mixins';

.tab-transparent {
  border-right: 1px solid $color-schedule-table-border;

  &_chosen {
    @include text-tabbar-accent;

    color: $color-text-active;
    background-color: $color-bg-tab-active;
  }
}
